$env: production;
@import '@/styles/_component-base.styles.scss';

.configurableDimensions {
    width: min-content;
    margin: auto;
    min-height: 672px;

    @include breakpoint(md) {
        min-height: 312px;
    }
    
    @include breakpoint(lg) {
        min-height: 544px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    // Images are too large to fit within these widths with the margin
    @media (max-width: 1100px) and (min-width: 1025px) {
        margin: -45px;
    }
    
    @media (max-width: 1200px) and (min-width: 1100px) {
        margin: 0;
    }

    @include breakpoint(md) {
        flex-direction: row;
    }
}

.overlay {
    max-width: fit-content;

    &.front-image {
        @include breakpoint(md) {
            padding-left: spacer(sm);
        }
    }
}

.sidePanel {
    @include breakpoint(lg) {
        img {
            width: 470px;
        }
    }

    @include breakpoint(xl) {
        img {
            width: auto;
        }
    }   
}


.text {
    font-size: set-fontSize(16);
    color: black;

    @include breakpoint(lg) {
        font-size: set-fontSize(20);
    }
}

.disclaimer {
    background-color: #f2ebe1;
    display: flex;
    justify-content: center;

    p {
        color: color(text, base);
        font-size: set-fontSize(12);
        font-weight: 200;
        line-height: 16px;
        padding: spacer(xs);
        text-align: center;
        margin-bottom: 0;
    
        @include breakpoint(md) {
            padding: spacer(sm) spacer(md);
            font-size: set-fontSize(14);
        }
    }
    

}
