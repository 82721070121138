$env: production;
@import '@/styles/_component-base.styles.scss';

.productAccordion {
    &__contentContainer ul {
        margin-left: spacer(base);
        margin-bottom: 0;
    }

    &__contentContainer strong {
        font-weight: bold;
    }

    &__accordionItem {
        border-radius: 8px;
        margin-bottom: spacer(sm) !important; // override the accordion margin in the bit component
    }

    &__numberedAccordion {
        display: inline-block;
        background-color: color(action, base);
        border: 0;
        border-radius: 100px;
        color: color(contrast, white);
        margin-right: spacer(xs);
        font-size: set-fontSize(12);
        left: 0;
        text-align: center;
        width: 18px;
        bottom: 1px;
    }
}